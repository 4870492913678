<template>
  <div class="d-flex justify-content-center">
    <div>
      <object
        data="/terms-and-conditions.pdf"
        width="870"
        height="790"
        type="application/pdf"
      >
        <p>
          This browser does not support PDFs. Please open the PDF to view it:
          <a href="/terms-and-conditions.pdf" target="_blank">Open PDF</a>.
        </p>
      </object>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  components: {},
  created() {
    console.log("route", this.$route);
  },
};
</script>
